import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/Layout"

class CategoryTemplate extends Component {
  render() {
    const category = this.props.data.category
    const editorial = this.props.data.editorial
    const posts = this.props.data.posts

    return (
      <Layout>
        <h1 dangerouslySetInnerHTML={{ __html: category.name }} />
        <div dangerouslySetInnerHTML={{ __html: category.description }} />
        <p>{category.count} posts</p>

        {editorial && (
          <>
            <hr />
            <small>our monthly editorial</small>
            <h2>{editorial.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: editorial.excerpt }} />
            <Link to={editorial.fields.slug}>read more</Link>
            <hr />
          </>
        )}

        <ul>
          {posts.edges.map(({ node: { title, excerpt, fields: { slug } } }) => (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
              <div dangerouslySetInnerHTML={{ __html: excerpt }} />
            </li>
          ))}
        </ul>
      </Layout>
    )
  }
}

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default CategoryTemplate

export const categoryQuery = graphql`
  query($id: String!) {
    category: wordpressCategory(id: { eq: $id }) {
      slug
      name
      description
      count
    }

    editorial: wordpressPost(
      acf: { is_editorial: { eq: true } }
      categories: { elemMatch: { id: { eq: $id } } }
    ) {
      slug
      title
      excerpt
      fields {
        slug
      }
    }

    posts: allWordpressPost(
      filter: {
        acf: { is_editorial: { ne: true } }
        categories: { elemMatch: { id: { eq: $id } } }
      }
    ) {
      edges {
        node {
          slug
          title
          excerpt
          fields {
            slug
          }
        }
      }
    }

    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
